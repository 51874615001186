import React from "react";

function Events() {
  return (
    <div className="events">
      <div className="eventsTitle">
        <p><span style={{fontWeight: "bold"}}>Upcoming Events</span></p> 
      </div>           
      <div className="upcomingEvents">
        <p>Check back here for upcoming author events! I will keep you posted! </p>          
      </div>        
    </div>
  );
}

export default Events;